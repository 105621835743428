<template>
    <v-dialog
      v-model="popup"
      max-width="600px"
      persistent
    >
        <v-card>
            <v-card-title>
                Update .so LIB Hash
            </v-card-title>
            <v-card-content>
                <v-text-field v-model="hash" label="Hash" class="pa-1 ma-1" />
            </v-card-content>
            <v-card-actions>
                <v-btn color="primary" @click="sync">
                    <v-icon
                        right
                    >
                        mdi-content-save-check
                    </v-icon>
                    Update
                </v-btn>
                <v-spacer />
                <v-btn color="danger" @click="cancel">
                    <v-icon
                        right
                    >
                        mdi-cancel
                    </v-icon>
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="onLoad">
            <v-progress-circular
                indeterminate
                size="64"
            />
        </v-overlay>
    </v-dialog>
</template>
<script>
export default {
    props:{
        onLoad: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            hash: '',
            canEmit: false,
            popup: false,
        }
    },
    methods:{
        open(hash){
            this.hash = hash;
            this.popup = true;
        },
        sync(){
            this.$emit('onUpdate', this.hash);
        },
        cancel(){
            this.popup = false;
            this.hash = "";
        }
    },
    created() {
        if (this.$listeners.onUpdate) {
            this.canEmit = true;
        }
    },
}
</script>